<template>
<div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Meu Perfil</h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
    <form class="needs-validation" @submit.prevent="salvarHolder">
        <card>
            <h3 slot="header" class="mb-0">Nova Senha</h3>
            <div class="form-row">
                <div class="col-md-4">
                    <base-input label="Nova Senha"
                      name="senha"
                      placeholder="Senha"
                      v-model="user.password" 
                      type="password">
                    </base-input>
                </div>
            </div>
        </card>
        <div class="col-md-12 text-center mb-3">
            <base-button type="primary" native-type="button" @click="changePasswd">Motificar Senha</base-button>
        </div>
    </form>   
   </div> <!--- fecha conteudo -->
  </div>
</template>

<script>
export default {
    data() {
        return {
            user:{
                password : null
            }
        };
    },
    methods:{
        changePasswd(){
            this.$clubApi.post('/user/update/passwd',{
                passwd : this.user.password
            })
            .then((response) => {
                this.$notify({type: 'success', message: "Atualização realizada"})
            }) .catch((error) => {
                this.$notify({type: 'warning', message: error.response.data.msg})
            }).finally(() =>{
                NProgress.done() 
            })
        }
    }
}
</script>

<style>

</style>
